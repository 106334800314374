.video {
    display:flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    min-height: 500px;
    max-width: 800px;
    padding: 20px;
}

@media screen and (max-width: 680px) {
    .video {
        min-height: 240px;
        max-width: 400px;
    }
}