.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px;
  margin-top: auto;
  margin-bottom: 0;
}

.px-2 {
  color: bisque !important;
  text-shadow: 2px 2px 2px black;
}

.copyright {
  color: bisque !important;
}

.footerBorder {
  border-color: rgb(214, 183, 157) !important;
}
