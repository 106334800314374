.infoCard {
  padding: 20px;
  background-color: bisque !important;
  font-family: "Old Standard TT", serif;
  font-weight: 900;
  width: 700px;
  height: auto;
  margin: 10px;
  align-content: center;
  margin-top: 35px;
}

.hours {
  text-align: center;
}

.mapsFb {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.FbContainer {
  width: 150px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 20px;
}

.connect {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .infoCard {
    width: 370px;
  }
}
