.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.awards-container {
    display: flex;
    justify-content: center;
    margin: 5px; 
    max-height: 1200px;
}

.award {
    margin: 1px; 
    display: block;
    width: 110px;
}

@media screen and (max-width: 775px) {
    .award {
      margin: 5px;
      width: 13%;
    }
}
  
@media screen and (min-width: 585px) and (max-width: 625px) {
    .award {
        margin: 5px;
        width: 11%
    }
}
@media screen and (max-width: 584px) {
    .container {
        flex-direction: column;
    }
    .award {
        width: 13%;
    }
}
  