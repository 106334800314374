.staffPic {
  width: 100%;
  max-width: 650px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 3px bisque;
  border-style: solid;
}

.staffTextDiv {
  text-align: center;
  font-family: "Old Standard TT", serif;
  color:bisque;
  text-shadow: 2px 2px 2px black;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.staffText {
  font-size: 25px;
}

@media screen and (max-width: 575px) {

  .staffText {
    font-size: 16px;
  }

  .staffPic {
    max-width: 375px;
  }
}

