@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@500&display=swap');

.aboutCard {
  padding: 12px;
  text-shadow: black 3px 3px 3px;
  font-family: 'Readex Pro', sans-serif;
  font-size:20px;
  word-spacing: 2px;
  color: bisque !important;
  width: auto;
  height: 100%;
  background-color: rgb(89, 108, 92) !important;
  text-align: center;
  max-width: 500px;
}

.cardLinks {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.cardLinks:hover {
    color: white !important;
}
  
  @media screen and (max-width: 575px) {
    .aboutCard {
        margin:20px;
        font-size: 18px;
        max-width: 300px;
        margin-top: 5px;
      }
  }
  