.profPic2 {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: auto;
  border: bisque;
  border-style: solid;
  border-width: 3px;
}

.bioCard2 {
  font-family: 'Belgrano', serif;
  color: bisque !important;
  width: auto;
  height: 100%;
  background-color: rgb(89, 108, 92) !important;
  text-align: center;
  max-width: 800px;
}

.bioBio2 {
  margin-top: 15px;
  display: flex;
  line-height: 1.3;
  font-size: 2vw;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 5px black;
}

.profDiv {
  display: flex;
  justify-content: center;
}

.bioName2 {
  font-size: 30px;
  text-shadow: 1px 1px 5px black;
}

@media screen and (min-width: 1500px) {
  .profPic2 {
    width: 100%;
    max-width: 400px;
    max-height: 600px;
    height: auto;
  }

  .bioCard2 {
    padding: 20px;
    max-height: 597px;
    margin-left: auto;
  }

  .bioBio2 {
    font-size: 21px;
  }
}
@media screen and (min-width: 1179px) and (max-width: 1500px) {
  .profPic2 {
    width: 100%;
    max-width: 400px;
    max-height: 600px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .bioCard2 {
    padding: 20px;
    margin-left: 11px;
    max-height: 600px;
  }

  .bioBio2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1179px) {
  .profPic2 {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-left: -11px;
  }

  .bioCard2 {
    padding: 10px;
    margin-left: 11px;
  }
  
  .bioBio2 {
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .profPic2 {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-left: -11px;
  }

  .bioCard2 {
    padding: 5px;
    margin-left: 11px;
  }

  .bioName2 {
    margin-top: 15px;
    font-size: 20px;
  }
  .bioBio2 {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 575px) {
  .profPic2 {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .bioCard2 {
    padding: 10px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .bioName2 {
    font-size: 4vw;
    margin-top: 15px;
  }

  .bioBio2 {
    font-size: 16px;
  }

  .pic2Collumn {
    order: 1;
  }

  .bio2Collumn {
    order: 2;
  }
}
