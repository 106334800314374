.navbar {
  background-color: bisque !important;
}

li a {
  color: rgb(6, 114, 11) !important;
  font-family: "Gilda Display", serif;
  font-weight: 1000;
  font-size: 20px;
}

.transpLogo {
  width: 100%;
  height: auto;
  max-width: 450px;
}

.dropdown-menu {
  background-color: rgb(89, 108, 92);
}

.dropdown-item {
  color: bisque !important;
  text-shadow: 2px 2px 2px black;
}

.dropdown-item:hover {
  background-color: rgb(133, 141, 110);
}

@media screen and (max-width: 575px) {
  .navbar-brand {
    max-width: 300px;
  }
}
