.laviathan {
  position: absolute;
  margin-left: -135px;
  left: 50%;
  margin-top: 400px;
}

.hercules {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  padding-top: 40px;
  padding-left:15px;
  padding-right: 15px;
}

.carousel-root {
  z-index: 2;
  position: relative;
}

/* .carouselSides {
  background-image: url('https://d1wedsacc4lfnu.cloudfront.net/floralpattern.png');
} */

.carousel img {
  border-radius: 15%;
}