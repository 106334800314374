@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.headingsText {
  font-size: 2px;
  font-family: "Dancing Script";
  text-align: center;
  color: bisque;
  margin-top: 30px;
  margin-bottom: 25px;
  text-shadow: 2px 2px 2px black;
}
