.clipboardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh; 
  /* transform: translate(33px, 0%); */
}

.clipboardContainer {
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
}
.clipboard {
  max-width: 100%;
  max-height: auto;
}

.devMessage {
  text-align: center;
  font-family: "BioRhyme", serif;
  font-weight: 900;
  font-size: 18px;
  color: bisque !important;
  text-shadow: 2px 2px 2px black;
  background-color: rgb(89, 108, 92) !important;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.containerZ2 {
  text-align: center;
  font-family: "BioRhyme", serif;
  font-size: 18px;
  color: bisque !important;
  background-color: rgb(89, 108, 92) !important;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.centeredText {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 600px;
}

.updateText h1 {
  color: bisque;
  text-shadow: 2px 2px 2px black;
  text-align: center;
  font-size: 25px;
  margin-top: 7px;
}

.updateContainer {
  height: auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

/* hr {
  border: 0;
  height: 4px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
} */

@media screen and (min-width: 575px) {
  .devMessage {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .containerZ2 {
    width: 100%;
    height: auto;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 575px) {
  .devMessage {
    padding: 10px;
    font-size: 14px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .containerZ2 {
    font-size: 14px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .containerZ2 h1 {
    text-align: left;
    margin: 5px;
    font-size: 20px
  }

  .containerZ2 h3 {
    margin: 3px;
    padding: 4px;
    text-align: right;
    font-size: 14px;
  }

  .updateContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    padding: 10px;
  }

  .updateText h1 {
    font-size: 13px;
  }
}
