.suiterultrasoundpic {
  width: 100%;
  height: auto;
  max-width: 700px;
  margin-top: 20px;
  border-style: solid;
  border-color: bisque;
}

.ultraDiv {
  border-radius: 30px;
  /* background-color: rgb(255, 228, 196, 0.5); */
  background-color: rgb(89, 108, 92) !important;
  word-spacing: 2px;
  z-index: 2;
  font-weight: 900;
  font-family: 'Readex Pro', sans-serif;
  margin-top: 15px;
  height: auto;
  padding: 20px;
}

.ultraBtn {
  width: 400px;
  padding: 10px !important;
  background-color: bisque !important;
  color: rgb(74, 97, 78) !important;
  font-family: "Corben", cursive !important;
  word-spacing: 3px;
  text-shadow:none;
}

.bioWithButton {
  text-align: center;
}

.ultraParentDiv {
  letter-spacing: 1.4px !important;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: bisque;
  text-shadow: 3px 3px 3px black;
}

@media screen and (min-width: 2000px) {
  .suiterultrasoundpic {
    margin-left: 200px;
    max-width: 675px;
  }

  .ultraDiv {
    max-width: 700px;
    font-size: 25px;
    margin-bottom: 15px;
  }

  .ultraParentDiv {
    margin-left: -200px !important;
  }

  .ultraBtn {
    width: 600px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1999px) {
  .ultraDiv {
    max-width: 700px;
    font-size: 25px;
    margin-bottom: 15px;
  }

  .ultraBtn {
    width: 600px;
  }

  .suiterultrasoundpic {
   max-width:675px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1499px) {
  .suiterultrasoundpic {
    max-width: 525px;
  }

  .ultraDiv {
    max-width: 550px;
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .suiterultrasoundpic {
    max-width: 450px;
    margin-top:0px;
  }

  .ultraDiv {
    max-width: 500px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .ultraDiv {
    max-width: 600px;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .suiterultrasoundpic {
    max-width: 400px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 651px) and (max-width: 767px) {
  .ultraDiv {
    max-width: 600px;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .suiterultrasoundpic {
    max-width: 400px;
  }
}

@media screen and (min-width: 501px) and (max-width: 650px) {
  .ultraDiv {
    max-width: 490px;
    margin-bottom: 25px;
    margin-top: 25px;
    font-size: 17px;
  }

  .suiterultrasoundpic {
    height: auto;
    width: 100%;
    max-width: 400px;
  }

  .ultraBtn {
    width: 400px;
  }
}

@media screen and (min-width: 431px) and (max-width: 500px) {
  .ultraDiv {
    font-size: 17px;
    max-width: 430px;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .suiterultrasoundpic {
    max-width: 400px;
  }

  .ultraBtn {
    width: 400px;
  }
}

@media screen and (min-width: 320px) and (max-width: 430px) {
  .ultraDiv {
    font-size: 13px;
    max-width: 320px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .ultraBtn {
    width: 300px;
  }

  .suiterultrasoundpic {
    max-width: 300px;
  }
}
