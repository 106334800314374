@import url('https://fonts.googleapis.com/css2?family=Belgrano&display=swap');

.profPic {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-left: 10px;
  border: bisque;
  border-style: solid;
  border-width: 3px;
}

.bioCard {
  margin-right: 10px;
  font-family: 'Belgrano', serif;
  color: bisque !important;
  width: auto;
  height: 100%;
  max-width: 800px;
  background-color: rgb(89, 108, 92) !important;
  text-align: center;
}

.bioBio {
  margin-top: 15px;
  display: flex;
  line-height: 1.3;
  font-size: 2vw;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 5px black;
}

.robot {
  display: flex;
  justify-content: center;
}

.bioName {
  font-size: 30px;
  text-shadow: 1px 1px 5px black;
}

@media screen and (min-width: 1500px) {
  .profPic {
    width: 100%;
    max-width: 400px;
    height: auto;
    max-height: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .bioCard {
    padding: 20px;
    max-height: 597px;
  }

  .bioBio {
    margin-top:30px;
    font-size: 22px;
  }
}

@media screen and (min-width: 1179px) and (max-width: 1500px) {
  .profPic {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .bioCard {
    padding: 20px;
  }

  .bioName {
    margin-top: 10px;
  }

  .bioBio {
    margin-top:30px;
    font-size: 20px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1178px) {
  .profPic {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .bioCard {
    padding: 10px;
  }

  .bioName {
    margin-top: 10px;
  }

  .bioBio {
    margin-top:20px;
    font-size: 1.7vw;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .profPic {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-left: 11px;
  }

  .bioCard {
    padding: 5px;
  }

  .bioName {
    font-size: 20px;
    margin-top: 10px;
  }

  .bioBio {
    font-size: 1.7vw;
  }
}

@media screen and (max-width: 575px) {
  .profPic {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .bioCard {
    padding: 10px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .bioName {
    font-size: 4vw;
    margin-top: 10px;
  }

  .bioBio {
    font-size: 16px;
  }
}
