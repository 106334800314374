.insurBtn {
    max-height: 237px;
    position: absolute;
    left: 50%;
    transform: translate(-190%, -40%);
    z-index: 10;
}

.insBtnContainer {
    max-width: 237px;
    margin: 0 auto;
    z-index: 10;
}
  
@media screen and (min-width: 768px) and (max-width: 900px) {
    .insurBtn {
        max-width: 150px;
        transform: translate(-260%, -30%);
    }
}

@media screen and (min-width: 714px) and (max-width: 767px) {
    .insurBtn {
        max-width: 150px;
        transform: translate(-170%, -20%);
    }
}

@media screen and (min-width: 575px) and (max-width: 713px) {
    .insurBtn {
        max-width: 150px;
        transform: translate(-170%, -10%);
    }
}

@media screen and (max-width: 574px) {
    .insurBtn {
        max-width: 150px;
        transform: translate(-170%, -20%);
    }
}
  
@media screen and (max-width: 489px) {
    .insurBtn {
      max-width: 120px;
      transform: translate(-180%, 0%);
    }
}